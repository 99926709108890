<template>
  <div class="bar-container">
    <vue-c3 ref="bar-chart" :handler="handler" style="height: 100%"></vue-c3>
  </div>
</template>

<script>
import Vue from 'vue';
import VueC3 from 'vue-c3';
import * as d3 from 'd3';
import { isBlank } from 'adready-api/helpers/common';
import * as CONSTANT from '~/constant';
import formatNumber from '~/util/numeral';

export default {
  name: 'BarChart',
  components: {
    VueC3,
  },
  props: {
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    legends: {
      type: Array,
      required: false,
      default: () => [],
    },
    tooltip: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => {
    return {
      handler: new Vue(),
    };
  },
  computed: {
    options() {
      const vm = this;
      return {
        data: {
          type: 'bar',
          columns: [],
          colors: {
            data1(d) {
              let { index } = d;
              if (index >= CONSTANT.COLOR_PATTERN.length) {
                index -= CONSTANT.COLOR_PATTERN.length;
              }
              return CONSTANT.COLOR_PATTERN[index];
            },
          },
        },
        legend: {
          show: false,
        },
        size: {
          height: 360,
          width: 360,
        },
        tooltip: {
          contents(d = []) {
            if (isBlank(vm.tooltip) && !vm.tooltip.length) {
              return '';
            }

            let tooltipBody = `<div class='ctooltip'>`;

            const legend = vm.legends[d[0].index];
            tooltipBody = tooltipBody.concat(
              `<div class='ctip-head-wrap'><div class='ctip-name'>${legend}</div></div>`
            );
            tooltipBody = tooltipBody.concat(`<div class='ctip-div'></div>`);

            const tooltipRows = vm.tooltip[d[0].index];
            for (let i = 0; i < tooltipRows.length; i++) {
              const { rowCss, columns } = tooltipRows[i];
              tooltipBody = tooltipBody.concat(`<div class='${rowCss}'>`);
              if (columns) {
                for (let j = 0; j < columns.length; j++) {
                  const { css, value } = columns[j];
                  tooltipBody = tooltipBody.concat(`<div class='${css}'>${value}</div>`);
                }
              }
              tooltipBody = tooltipBody.concat('</div>');
            }

            tooltipBody = tooltipBody.concat('</div>');
            return tooltipBody;
          },
        },
        axis: {
          x: {
            show: false,
          },
          y: {
            tick: {
              format(d) {
                return formatNumber(d, 'percentage', '0');
              },
            },
          },
        },
        bar: {
          width: {
            ratio: 1,
          },
        },
      };
    },
  },
  watch: {
    columns() {
      const vm = this;

      vm.render();
    },
  },
  mounted() {
    const vm = this;

    vm.render();
  },
  beforeDestroy() {
    const vm = this;

    vm.handler.$emit('destroy');
  },
  methods: {
    render() {
      const vm = this;
      const chartData = [['data1', ...vm.columns]];

      vm.handler.$emit('destroy');

      d3.select('.bar-legend').remove();

      vm.handler.$emit('init', vm.options);

      vm.handler.$emit('dispatch', (chart) => {
        chart.load({
          columns: chartData,
        });
      });

      vm.handler.$emit('dispatch', (chart) => {
        const legendsSec = d3
          .select('.bar-container')
          .insert('div', '.bar-chart')
          .attr('class', 'bar-legend')
          .append('table')
          .selectAll('tr');

        legendsSec
          .data(vm.legends)
          .enter()
          .append('tr')
          .attr('bar-data-id', (id) => {
            return id;
          })
          .html((data, i) => {
            let index = i;
            if (index >= CONSTANT.COLOR_PATTERN.length) {
              index -= CONSTANT.COLOR_PATTERN.length;
            }

            const str = `<td><div style="border-left-color: ${CONSTANT.COLOR_PATTERN[index]}">${data}</div></td>`;
            return str;
          })
          .on('mouseover', (id) => {
            chart.focus(id);
          })
          .on('mouseout', () => {
            chart.revert();
          })
          .on('click', (id) => {
            chart.toggle(id);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.bar-container {
  position: relative;

  svg {
    width: 100%;
  }
  table {
    border-collapse: inherit !important;
  }
  .c3 text {
    fill: #7e8890;
    font-size: 12px;
  }
  .c3-axis-x text {
    font-size: 13px;
    max-width: 200px;
  }
  .c3-legend-item {
    font-size: 12px;
  }
  .c3-legend-item text {
    font-size: 13px;
  }
  .bar-chart {
    width: 100%;
    height: 100%;
  }
  .c3-chart-arc path {
    stroke: rgba(0, 0, 0, 0.1);
  }
  .c3 line,
  .c3 path {
    stroke: rgba(180, 180, 180, 0.2);
  }

  .bar-legend th,
  .bar-legend td.values {
    height: 1.2rem;
    padding-left: 6px;
    margin: 5px 0px;
    overflow: hidden;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 0.75rem;
    color: rgba(130, 130, 140, 0.8);
    text-align: left;
    cursor: pointer;
  }

  .bar-legend td > div {
    text-align: left;
    cursor: pointer;
    color: rgba(130, 130, 140, 0.8);
    border-left-style: solid;
    border-left-width: 12px;
    padding-left: 8px;
    margin: 5px 0px;
    font-size: 12px;
    font-family: sans-serif;
    overflow: hidden;
    line-height: 0.75rem;
    max-width: 220px;
    text-overflow: ellipsis;
  }

  .bar-legend th:first-child {
    padding-left: 19px;
  }
  .bar-legend th:last-child {
    padding-left: 20px;
  }
  .bar-legend td:last-child {
    text-align: right;
  }
  .bar-legend td {
    white-space: nowrap;
    vertical-align: middle;
  }

  .bar-legend {
    position: absolute;
    top: 8%;
    right: 55px;
    width: auto;
    height: 270px;
    padding-right: 20px;
    overflow-y: scroll;
    text-align: left;
  }

  .c3-tooltip-container {
    border-radius: 4px;
    overflow: hidden;
  }
  .ctooltip {
    background-color: #1a1b1d;
    padding: 10px 15px;
    width: 220px;
    font-size: 12px;
    // margin-top: 0px;
    border-radius: 4px;
    opacity: 0.96;
    min-height: 200px;
  }

  .ctip-div {
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 8px;
  }

  .ctip-name {
    font-size: 13px;
    float: left;
    width: 100%;
    text-align: left;
  }

  .ctip-val {
    display: inline-block;
    float: right;
    font-size: 13px;
  }

  .ctip-head-wrap {
    color: #fff;
    font-size: 12px;
    line-height: 1.3em;
    display: flex;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .ctip-dif-wrap,
  .ctip-row-wrap {
    color: #abb2c5;
    display: flex;
    width: 100%;
  }

  .ctip-row-wrap.exp {
    color: var(--primarycolor);
    display: flex;
    width: 100%;
  }

  .ctip-positive {
    color: var(--primarygreen);
    // display: inline-block;
    float: right;
  }

  .ctip-negative {
    color: var(--primaryred);
    // display: inline-block;
    float: right;
  }

  .ctip-dif-name,
  .ctip-row-name {
    font-size: 13px;
    vertical-align: top;
    width: 100%;
    text-align: left;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .ctip-dif,
  .ctip-row-val {
    float: right;
    font-size: 13px;
    vertical-align: top;
  }

  .ctip-head-wrap .ctip-dif {
    font-size: 13px;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .ctip-perc {
    opacity: 0.6;
  }
  .c3-xgrid-focus {
    display: none;
  }
}
</style>
